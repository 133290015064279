body {
    background-color: lightgray;
}

.navbar {
    background-color: #343a40;
}
.navbar .navbar-brand {
    color: #ecf0f1;
}
.navbar .navbar-brand:hover,
.navbar .navbar-brand:focus {
    color: #585151;
}
.navbar .navbar-text {
    color: #ecf0f1;
}
.navbar .navbar-text a {
    color: #585151;
}
.navbar .navbar-text a:hover,
.navbar .navbar-text a:focus {
    color: #585151;
}
.navbar .navbar-nav .nav-link {
    color: #ecf0f1;
    border-radius: .25rem;
    margin: 0 0.25em;
}
.navbar .navbar-nav .nav-link:not(.disabled):hover,
.navbar .navbar-nav .nav-link:not(.disabled):focus {
    color: #585151;
}
.navbar .navbar-nav .dropdown-menu {
    background-color: #343a40;
    border-color: #525050;
}
.navbar .navbar-nav .dropdown-menu .dropdown-item {
    color: #ecf0f1;
}
.navbar .navbar-nav .dropdown-menu .dropdown-item:hover,
.navbar .navbar-nav .dropdown-menu .dropdown-item:focus,
.navbar .navbar-nav .dropdown-menu .dropdown-item.active {
    color: #585151;
    background-color: #525050;
}
.navbar .navbar-nav .dropdown-menu .dropdown-divider {
    border-top-color: #525050;
}
.navbar .navbar-nav .nav-item.active .nav-link,
.navbar .navbar-nav .nav-item.active .nav-link:hover,
.navbar .navbar-nav .nav-item.active .nav-link:focus,
.navbar .navbar-nav .nav-item.show .nav-link,
.navbar .navbar-nav .nav-item.show .nav-link:hover,
.navbar .navbar-nav .nav-item.show .nav-link:focus {
    color: #585151;
    background-color: #525050;
}
.navbar .navbar-toggle {
    border-color: #525050;
}
.navbar .navbar-toggle:hover,
.navbar .navbar-toggle:focus {
    background-color: #525050;
}
.navbar .navbar-toggle .navbar-toggler-icon {
    color: #ecf0f1;
}
.navbar .navbar-collapse,
.navbar .navbar-form {
    border-color: #ecf0f1;
}
.navbar .navbar-link {
    color: #ecf0f1;
}
.navbar .navbar-link:hover {
    color: #585151;
}

@media (max-width: 575px) {
    .navbar-expand-sm .navbar-nav .show .dropdown-menu .dropdown-item {
        color: #ecf0f1;
    }
    .navbar-expand-sm .navbar-nav .show .dropdown-menu .dropdown-item:hover,
    .navbar-expand-sm .navbar-nav .show .dropdown-menu .dropdown-item:focus {
        color: #585151;
    }
    .navbar-expand-sm .navbar-nav .show .dropdown-menu .dropdown-item.active {
        color: #585151;
        background-color: #525050;
    }
}

@media (max-width: 767px) {
    .navbar-expand-md .navbar-nav .show .dropdown-menu .dropdown-item {
        color: #ecf0f1;
    }
    .navbar-expand-md .navbar-nav .show .dropdown-menu .dropdown-item:hover,
    .navbar-expand-md .navbar-nav .show .dropdown-menu .dropdown-item:focus {
        color: #585151;
    }
    .navbar-expand-md .navbar-nav .show .dropdown-menu .dropdown-item.active {
        color: #585151;
        background-color: #525050;
    }
}

@media (max-width: 991px) {
    .navbar-expand-lg .navbar-nav .show .dropdown-menu .dropdown-item {
        color: #ecf0f1;
    }
    .navbar-expand-lg .navbar-nav .show .dropdown-menu .dropdown-item:hover,
    .navbar-expand-lg .navbar-nav .show .dropdown-menu .dropdown-item:focus {
        color: #585151;
    }
    .navbar-expand-lg .navbar-nav .show .dropdown-menu .dropdown-item.active {
        color: #585151;
        background-color: #525050;
    }
}

@media (max-width: 1199px) {
    .navbar-expand-xl .navbar-nav .show .dropdown-menu .dropdown-item {
        color: #ecf0f1;
    }
    .navbar-expand-xl .navbar-nav .show .dropdown-menu .dropdown-item:hover,
    .navbar-expand-xl .navbar-nav .show .dropdown-menu .dropdown-item:focus {
        color: #585151;
    }
    .navbar-expand-xl .navbar-nav .show .dropdown-menu .dropdown-item.active {
        color: #585151;
        background-color: #525050;
    }
}

.navbar-expand .navbar-nav .show .dropdown-menu .dropdown-item {
    color: #ecf0f1;
}
.navbar-expand .navbar-nav .show .dropdown-menu .dropdown-item:hover,
.navbar-expand .navbar-nav .show .dropdown-menu .dropdown-item:focus {
    color: #585151;
}
.navbar-expand .navbar-nav .show .dropdown-menu .dropdown-item.active {
    color: #585151;
    background-color: #525050;
}

.ActionsType_singles-singles-actions {
    float: left;
}

.grid-striped .row:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
}